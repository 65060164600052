export function isDescendantOf(
  childElement: HTMLElement,
  parentElement: HTMLElement
): boolean {
  let currentNode: Node | null = childElement.parentNode;

  while (currentNode !== null) {
    if (currentNode === parentElement) {
      return true;
    }

    currentNode = currentNode.parentNode;
  }

  return false;
}

export function writeUrlParameter(key: string, value: string) {
  var path = window.location.pathname;
  var page = path.split("/").pop();
  window.history.replaceState(null, "", page + "?" + key + "=" + value);
}

export function limitStringSize(val: string, size: number = 50): string {
  if (val.length > size) {
    return val.slice(0, size) + "...";
  }
  return val;
}
