
import { getState } from "@/state/IvoryState";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state: getState(),
    };
  },
  methods: {
    close() {
      this.$el.classList.add("bannerSlide");
      // this.$el.style.display = "none";
    },
  },
});
