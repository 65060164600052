
import { defineComponent } from "vue";
import GenreRecord from "@/model/genres/GenreRecord";
import { getHomeState } from "@/state/HomeState";
import { getState } from "@/state/IvoryState";

export default defineComponent({
  data() {
    return {
      state: getState(),
    };
  },
  mounted() {
    if (this.big) {
      this.$el.classList.add("big");
    }
  },
  props: {
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    genre: {
      type: GenreRecord,
      required: true,
    },
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
