import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d4d8c80"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "home-container",
  style: {"padding-bottom":"0px"}
}
const _hoisted_2 = { class: "flex align-center" }
const _hoisted_3 = { class: "item-list" }
const _hoisted_4 = { class: "state-row" }
const _hoisted_5 = {
  key: 1,
  class: "light f16 hbold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!
  const _component_IvoryInput = _resolveComponent("IvoryInput")!
  const _component_Genres = _resolveComponent("Genres")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!
  const _component_SongRow = _resolveComponent("SongRow")!
  const _component_ArtistRow = _resolveComponent("ArtistRow")!
  const _component_FulfillingBouncingCircleSpinner = _resolveComponent("FulfillingBouncingCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.results.getLength() > 0)
        ? (_openBlock(), _createBlock(_component_IvoryIcon, {
            key: 0,
            onClick: _ctx.back,
            icon: "back"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_IvoryInput, {
        textChange: _ctx.onSearchChange,
        ref: "searchInput",
        modelValue: _ctx.homeState.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.homeState.search) = $event)),
        ready: _ctx.performSearch,
        class: "grow",
        placeholder: "Title, artist",
        icon: "search"
      }, null, 8, ["textChange", "modelValue", "ready"])
    ]),
    (!_ctx.searching && _ctx.results.getLength() == 0 && _ctx.resultMessage == '')
      ? (_openBlock(), _createBlock(_component_Genres, {
          key: 0,
          click: _ctx.onGenreClick
        }, null, 8, ["click"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.songs, (song) => {
        return (_openBlock(), _createBlock(_component_SongRow, {
          onClick: () => _ctx.playSong(song),
          song: song,
          tailing: true
        }, {
          default: _withCtx(() => [
            (_ctx.state.loggedIn() && _ctx.state.account?.role == 5)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (song.pendingRemove)
                    ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
                        key: 0,
                        size: 30,
                        color: "#c41627"
                      }))
                    : (_openBlock(), _createBlock(_component_IvoryIcon, {
                        key: 1,
                        onClick: [
                          _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                          () => _ctx.removeSong(song)
                        ],
                        icon: "trash"
                      }, null, 8, ["onClick"]))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["onClick", "song"]))
      }), 256)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.artists, (artist) => {
        return (_openBlock(), _createBlock(_component_ArtistRow, {
          onClick: () => _ctx.searchByArtist(artist.name),
          artist: artist
        }, null, 8, ["onClick", "artist"]))
      }), 256)),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.searching)
          ? (_openBlock(), _createBlock(_component_FulfillingBouncingCircleSpinner, {
              key: 0,
              color: "#c4c4c4"
            }))
          : _createCommentVNode("", true),
        (_ctx.resultMessage != '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.resultMessage), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}