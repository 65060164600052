
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },

  expose: ["toggleLoading", "isLoading"],
  data() {
    return { loading: false };
  },

  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
    toggleLoading(loading: boolean) {
      this.loading = loading;
    },
    isLoading() {
      return this.loading;
    },
  },
});
