
import { defineComponent, nextTick } from "vue";
import * as TimeUtils from "@/utils/TimeUtils";
import IvoryButton from "./global/IvoryButton.vue";
import { Midi } from "@tonejs/midi";
import { getState } from "@/state/IvoryState";
import * as ColorUtils from "@/utils/ColorUtils";
import NoteFlow from "./player/flow/NoteFlow";
import * as Constants from "@/Constants";
import { useRoute, useRouter } from "vue-router";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";
import * as IvoryApi from "@/IvoryApi";
import { HalfCircleSpinner } from "epic-spinners";

export default defineComponent({
  expose: ["onPauseChange"],
  components: { IvoryButton },
  data() {
    var state = getState();

    return {
      TimeUtils: TimeUtils,
      state: state,
      ColorUtils: ColorUtils,
      currentTime: 0,
      DOMUtils: DOMUtils,
    };
  },

  mounted() {
    this.$el.style.display = this.state.minimal ? "none" : "flex";

    document.addEventListener("keydown", this.onKeyDown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  setup() {
    var router = useRouter();
    return {
      router: router,
    };
  },

  methods: {
    toggleEditMode() {
      this.state.noteFlow!.editMode = !this.state.noteFlow!.editMode;

      var refs = <any>this.$refs;

      if (this.state.noteFlow!.editMode) {
        this.state.noteFlow?.setPause(true);
        refs.editBtn.source = "ico/edit-active.svg";
      } else {
        refs.editBtn.source = "ico/edit.svg";
      }
    },
    downloadClick() {
      this.state.downloadMidi(this.state.noteFlow!.song!);
    },
    sheetClick() {
      this.state.openSheet(this.state.noteFlow!.song!);
    },
    goLogin() {
      this.state.noteFlow?.stop();
      this.router.push("login");
    },
    onKeyDown(event: any) {
      if (event.key === " ") {
        this.togglePause();
      }
      if (event.key == "ArrowUp") {
        this.state.noteFlow!.progressivePlayback(1);
      } else if (event.key == "ArrowDown") {
        this.state.noteFlow!.progressivePlayback(-1);
      }

      if (event.key == "ArrowLeft") {
        this.transpose(-1);
      } else if (event.key == "ArrowRight") {
        this.transpose(1);
      }
    },
    goLibrary() {
      this.state.noteFlow?.stop();
      this.router.replace({ name: "home" });
    },

    openYoutubeLink() {
      let url = this.state.noteFlow!.song!.youtubeUrl!;

      window.open(url, "_blank");
    },

    loopClick() {
      var refs = <any>this.$refs;

      if (this.state.loopEnd != null) {
        this.state.loopStart = null;
        this.state.loopEnd = null;
        refs.loopIcon.source = "ico/loop.svg";
        return;
      }
      if (this.state.loopStart == null) {
        this.state.loopStart = this.state.noteFlow!.timeCurrent;
        refs.loopIcon.source = "ico/loop-orange.svg";
      } else {
        this.state.loopEnd = this.state.noteFlow!.timeCurrent;
        refs.loopIcon.source = "ico/loop-green.svg";
      }
    },
    getFavIcon() {
      let exists = this.state.favorites?.some(
        (x) => x.id == this.state.noteFlow!.song!.id!
      );

      if (exists) {
        return "star-fav";
      } else {
        return "star-empty-fav";
      }
    },
    async favoriteClick() {
      var refs = <any>this.$refs;

      let exists = await IvoryApi.toggleFavorite(
        this.state.noteFlow?.song!.id!
      );

      if (!exists) {
        let index = this.state.favorites?.indexOf(this.state.noteFlow?.song!);
        this.state.favorites?.splice(index!, 1);
        refs.favIcon.source = "/ico/star-empty-fav.svg";
      } else {
        this.state.favorites?.push(this.state.noteFlow?.song!);

        refs.favIcon.source = "/ico/star-fav.svg";
      }
    },
    async rateSong(n: number) {
      await IvoryApi.rateSong(this.state.noteFlow?.song?.id!, n);
    },

    togglePause() {
      this.state.noteFlow?.togglePause()!;
    },

    stop() {
      this.state.noteFlow!.setPause(true);
      this.state.noteFlow!.timeCurrent = 0;
      /*  this.state.noteFlow?.stop();
      this.state.querySongId = null; */
    },
    addTempoMultiplier(delta: number) {
      var nextTm = Math.round((this.state.timeMultiplier + delta) * 100) / 100;

      if (nextTm <= 2.0 && nextTm > 0) {
        this.state.timeMultiplier = nextTm;
      }
    },

    transpose(delta: number) {
      this.state.keyboard?.removeInputs();
      this.state.noteFlow!.addTranspose(delta);
    },

    toggleSettingsBar() {
      var parent = <any>this.$parent;
      parent.openSettingsBar();
    },
    onPauseChange(value: boolean) {
      var refs = <any>this.$refs;

      if (refs.pauseIcon) {
        if (value) {
          refs.pauseIcon.source = "/ico/play.svg";
        } else {
          refs.pauseIcon.source = "/ico/pause.svg";
        }
      }
    },
  },
});
