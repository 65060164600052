
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import * as SoundManager from "@/managers/SoundManager";
import * as MidiDeviceManager from "@/managers/MidiDeviceManager";

import { useRouter } from "vue-router";
import IvoryIcon from "@/components/global/IvoryIcon.vue";
import * as IvoryApi from "@/IvoryApi";
import { getHomeState } from "@/state/HomeState";

export default defineComponent({
  computed: {
    loadingLayout(): any {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },
  async mounted() {
    // MidiDeviceManager.initialize();

    if (this.state.account == null) {
      this.state.account = await IvoryApi.getLoggedAccount();
    }

    // here get a result status to know if API is available

    if (this.state.account != null) {
      this.state.onAccountReceived();
      this.state.favorites = await IvoryApi.getFavorites();
    }

    this.homeState.genres = await IvoryApi.getGenres();

    if (this.homeState.genres == null) {
      this.error = true;
      this.message =
        "Unable to connect to Ivory API, the server may be under maintenance.";
      return;
    }

    await SoundManager.initialize("ivory-keys", this.onSoundProgressChange);

    this.state.computeKeyboardSize();
    //   this.state.updateNoteColor(47,115,167);
    //this.state.updateNoteColor(224, 162, 45);
    //  this.state.updateNoteColor(63, 128, 198);
    this.state.initialized = true;

    if (this.state.querySongId == null) {
      this.router.replace({ name: "home" });
    } else {
      this.router.replace({ name: "player" });
    }
  },

  methods: {
    onSoundProgressChange(value: number) {
      this.loadingPercent = Math.round(value * 100);
    },
    sleep(milliseconds: number) {
      const date = Date.now();
      let currentDate = Date.now();
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
  },

  data() {
    return {
      homeState: getHomeState(),
      state: getState(),
      router: useRouter(),
      message: "",
      error: false,
      loadingPercent: 0,
    };
  },

  components: { IvoryIcon },
});
