
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";

export default defineComponent({
  props: ["artist"],
  data() {
    return {
      state: getState(),
    };
  },
  components: { HalfCircleSpinner },
});
