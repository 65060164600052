
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import SongRow from "./SongRow.vue";
import router from "@/router";

export default defineComponent({
  data() {
    var state = getState();

    return {
      state: state,
    };
  },

  methods: {
    async removeFromFavorite(song: IvorySong) {
      let result = await IvoryApi.toggleFavorite(song.id);

      if (!result) {
        let index = this.state.favorites?.indexOf(song)!;
        this.state.favorites?.splice(index, 1);
      }
    },
    async onHistorySongClick(song: IvorySong) {
      var state = getState();

      state.querySongId = song.id;
      router.push("player");
    },
  },
  components: { SongRow },
});
