import { KeyColor } from "../KeyColor";

export default class IvoryNote {
  public number: number = 0;

  public start: number = 0;

  public duration: number = 0;

  public realDuration: number = 0;

  public velocity: number = 0;

  public leftHand: boolean = false;
}
