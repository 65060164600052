
import { defineComponent } from "@vue/runtime-core";
import { KeyColor } from "@/model/KeyColor";
import * as NoteManager from "@/managers/NoteManager";
import { getState } from "@/state/IvoryState";
import FallingNote from "@/components/player/flow/FallingNote";

import * as SoundManager from "@/managers/SoundManager";
import IvoryNote from "@/model/songs/IvoryNote";
import SoundElement from "@/model/sound/SoundElement";
import * as ColorUtils from "@/utils/ColorUtils";

const __default__ = defineComponent({
  props: {
    number: {
      type: Number,
      required: true,
    },
  },

  expose: [
    "addInput",
    "removeInput",
    "removeSound",
    "hasInput",
    "removeAllInputs",
  ],

  data() {
    var color = NoteManager.getKeyColor(this.number);
    var label = NoteManager.getNote(this.number);
    return {
      KeyColor: KeyColor,
      color: color,
      ColorUtils: ColorUtils,
      state: getState(),
      label: label,
      inputCounts: 0,
      sounds: [] as SoundElement[],
      lastIsLeftHand: false,
    };
  },

  methods: {
    removeAllInputs() {
      this.inputCounts = 0;
      this.onInputsUpdated(false);
    },

    removeSound() {
      if (this.sounds.length > 0) {
        var lastSound = this.sounds.shift()!;

        SoundManager.fade(lastSound);
      }
    },
    removeInput() {
      if (this.inputCounts == 0) {
        return;
      }

      this.inputCounts--;

      this.onInputsUpdated(false);
    },

    onInputsUpdated(added: boolean) {
      if (this.inputCounts == 0) {
        if (this.color == KeyColor.White) {
          this.$el.classList.remove("white-input-lh");
          this.$el.classList.remove("white-input");
        } else {
          this.$el.classList.remove("black-input-lh");
          this.$el.classList.remove("black-input");
        }
      } else {
        if (added) {
          if (this.color == KeyColor.White) {
            if (this.lastIsLeftHand) {
              this.$el.classList.add("white-input-lh");
            } else {
              this.$el.classList.add("white-input");
            }
          } else {
            if (this.lastIsLeftHand) {
              this.$el.classList.add("black-input-lh");
            } else {
              this.$el.classList.add("black-input");
            }
          }
        }
      }
    },
    hasInput() {
      return this.inputCounts > 0;
    },
    addInput(play: boolean, velocity: number, lh: boolean) {
      if (play) {
        this.sounds.push(SoundManager.play(this.number, velocity));
      }

      this.inputCounts++;

      this.lastIsLeftHand = lh;

      this.onInputsUpdated(true);
    },
    onClick() {
      var state = getState();

      if (state.noteFlow == null) {
        return;
      }

      var ivoryNote = new IvoryNote();
      ivoryNote.number = this.number;
      ivoryNote.duration = 0.01;
      ivoryNote.velocity = 100;
      ivoryNote.start = state.noteFlow?.timeCurrent! - 100;
      state.noteFlow!.addNote(ivoryNote);
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "23881a09": (_ctx.state.whiteKeyWidth.toString() + "px"),
  "d8c247b0": (_ctx.state.whiteKeyHeight.toString() + "px"),
  "b2fad974": (_ctx.state.keyMargin.toString() + "px"),
  "7ea81e5f": (_ctx.state.blackKeyWidth.toString() + "px"),
  "c7013adc": (_ctx.state.blackKeyHeight.toString() + "px"),
  "fda3e970": ("-" + ((_ctx.state.blackKeyWidth / 2) + (_ctx.state.keyMargin / 2)) + "px"),
  "5b007fe1": (_ctx.state.getNoteColorHTML(1)),
  "19e5586c": (_ctx.state.getNoteColorLHHex()),
  "5b846a47": (_ctx.state.noteR),
  "5b846a3c": (_ctx.state.noteG),
  "5b846a37": (_ctx.state.noteB),
  "130970c3": (-_ctx.state.keyBrightnessMultiplier),
  "e7da31ea": (_ctx.state.noteLHR),
  "e7da3200": (_ctx.state.noteLHG),
  "e7da320a": (_ctx.state.noteLHB),
  "723721d8": (_ctx.state.keyBrightnessMultiplier)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__