import { KeyColor } from "@/model/KeyColor";

export function getKeyColor(number: number): KeyColor {
    let relativePosition = number % 12;

    let blackPositions = [1, 4, 6, 9, 11];

    if (blackPositions.includes(relativePosition)) {
        return KeyColor.Black;
    }
    else {
        return KeyColor.White;
    }
}

export function getNote(number : number): string
{
    var symbols = ['A','A#','B','C','C#','D','D#',"E",'F','F#','G','G#'];

    return symbols[number % 12];
}

export function getOctave(number :number):number
{
    return number % 12;
}
