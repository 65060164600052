import { getState } from "@/state/IvoryState";
import * as SoundManager from "@/managers/SoundManager";
import IvoryNote from "@/model/songs/IvoryNote";

export let hooks: Function[] = [];

export function initialize() {
  var nav = navigator as any;

  try {
    nav.requestMIDIAccess().then(
      (access: any) => {
        startLoggingMIDIInput(access, 0);
      },
      (error: any) => {
        console.log(error);
      }
    );
  } catch {
    console.log("Midi device not available");
  }
}

export function addHook(hook: Function) {
  hooks.push(hook);
}

export function startLoggingMIDIInput(midiAccess: any, indexOfPort: any) {
  midiAccess.inputs.forEach((entry: any) => {
    entry.onmidimessage = onMIDIMessage;
  });
}

export function onMIDIMessage(event: any) {
  var noteNumber = event.data[1] - 21;

  if (event.data[0] == 144) {
    for (let hook of hooks) {
      hook(noteNumber, true);
    }
    /* if (
      state.learningMode.pendingNotes.find((x) => true)?.number == noteNumber
    ) {
      state.learningMode.pendingNotes.shift();

      if (state.learningMode.pendingNotes.length == 0) {
        state.noteFlow?.setPause(false);
      }
    }

    state.keyboard!.addInput(noteNumber, !state.learningMode.active, 50, false); */
  }

  if (event.data[0] == 128) {
    for (let hook of hooks) {
      hook(noteNumber, false);
    }
    //  state.keyboard!.removeInput(noteNumber);
  }
}
