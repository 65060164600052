import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d48a76c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-section flex col g24" }
const _hoisted_2 = { class: "bold genre-name" }
const _hoisted_3 = { class: "description f14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    style: _normalizeStyle(
      _ctx.image
        ? 'background: linear-gradient(to bottom ,rgba( 0, 0, 0,0.6), rgba(0,0,0,0.7));'
        : 'background:rgb(40, 40, 40,0.2)'
    )
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.genre.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.genre.description), 1)
    ])
  ], 4))
}