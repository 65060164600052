
import { defineComponent } from "vue";
import Keyboard from "./player/keys/Keyboard.vue";
import NoteFlowHost from "./player/flow/NoteFlowHost.vue";
import { Midi } from "@tonejs/midi";
import IvoryButton from "./global/IvoryButton.vue";

import { getState } from "@/state/IvoryState";
import * as IvoryApi from "@/IvoryApi";
import SettingsBar from "@/components/player/bars/SettingsBar.vue";

export default defineComponent({
  expose: ["openSettingsBar", "displayPlaybackOverlay"],
  components: {
    NoteFlowHost,
    Keyboard,
    IvoryButton,
    SettingsBar,
  },

  data() {
    return {
      state: getState(),
    };
  },

  computed: {
    flowStyle(): any {
      return {
        borderTopRightRadius: "12px",
        borderTopLeftRadius: "12px",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url(${this.state.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },
  async mounted() {
    let state = getState();

    if (state.querySongId != null) {
      var result = await IvoryApi.requestSong(state.querySongId, true);
      await state.playSong(result!);
    }
  },
  methods: {
    displayPlaybackOverlay() {
      var refs = <any>this.$refs;
      var state = getState();
      state.noteFlow?.setPause(true);

      refs.flow.togglePlaybackOverlay(true);
    },

    openSettingsBar() {
      var refs = <any>this.$refs;
      refs.settingsBar.toggle();
    },
  },
});
