import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvorySheet from "@/model/sheets/IvorySheet";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";
import * as ChordManager from "@/managers/ChordManager";
import { StaveEnum } from "@/model/sheets/StaveEnum";
import * as VexflowUtils from "@/utils/VexflowUtils";
import VF, {
  Annotation,
  Beam,
  Dot,
  Note,
  Stave,
  StaveNote,
  StaveTie,
  Voice,
} from "vexflow";
import { nextTick } from "vue";
import IvoryStave from "@/model/sheets/IvoryStave";
import IvoryTimeSignature from "@/model/sheets/IvoryTimeSignature";

export let STAVE_WIDTH = 340;

export let SHEET_Y_OFFSET = 200;

export let PADDING_X = 50;

export let GAP_BETWEEN_GRAND_STAFF = 280;

export let GAP_BETWEEN_STAFF = 120;

export let STAVE_PER_LINE = 4;

export default class SheetDrawer {
  canvas: HTMLCanvasElement;
  sheet: IvorySheet;

  context: VF.RenderContext | null;

  allNotes: Map<IvoryStaveNote, Note> = new Map();

  x: number = 0;
  y: number = 0;

  constructor(canvas: HTMLCanvasElement, sheet: IvorySheet) {
    this.canvas = canvas;
    this.sheet = sheet;
    this.context = null;

    STAVE_WIDTH =
      window.innerWidth / STAVE_PER_LINE - PADDING_X / (STAVE_PER_LINE / 2);
  }

  getTimeSignature(barId: number): IvoryTimeSignature | undefined {
    var ts = this.sheet.timeSignatures.find((x) => x.barId <= barId);
    if (ts?.barId == barId) {
      console.log("Found ts at " + ts.barId);
    }
    return ts;
  }
  renderBar(
    ivoryStave: IvoryStave,
    bar: IvoryMeasure,
    barX: number,
    barY: number
  ): VF.Stave | null {
    var clef = this.getClef(ivoryStave.staveEnum);

    var stave = new VF.Stave(barX, barY, STAVE_WIDTH);

    var ts = this.getTimeSignature(bar.number);

    if (ts?.barId == bar.number) {
      console.log("Adding ts at " + ts.barId);
      stave.addTimeSignature(
        ts.numberOfBeatPerMeasure + "/" + ts.beatNoteValue
      );
    }

    if (this.x == 0) {
      stave.addKeySignature("G").addClef(clef);
    }

    if (this.x == 0 && this.y == 0 && ivoryStave.id == 0) {
      stave!.setTempo(
        { bpm: this.sheet.tempo, duration: "q", dots: 0, name: "Tempo" },
        -60
      );
    }

    var notes: VF.StaveNote[] = [];

    for (let ivoryStaveNote of bar.notes) {
      var beamNotes: IvoryStaveNote[] = [];

      if (ivoryStaveNote.beamId != null) {
        beamNotes = bar.notes.filter((x) => x.beamId == ivoryStaveNote.beamId);
      }

      var staveNote = new VF.StaveNote({
        clef: clef,
        keys: ivoryStaveNote.keys,
        duration: ivoryStaveNote.duration,
        stem_direction: this.getSteamDirection(ivoryStaveNote, clef, beamNotes),
      });
      notes.push(staveNote);
    }

    for (let i = 0; i < bar.notes.length; i++) {
      var n = bar.notes[i];

      this.allNotes.set(n, notes[i]);

      if (n.duration.includes("d")) {
        Dot.buildAndAttach([notes[i]]);
      }

      if (n.keys.length >= 3) {
        var ids = n.keys.map((x) => VexflowUtils.getNoteId(x));
        /*var chord = ChordManager.getChordNameFromIds(ids, false);

        if (chord) {
          notes[i].addModifier(
            new VF.Annotation(chord!)
              .setFont("Arial", 10, "normal")
              .setStyle({ fillStyle: "rgb(120,120,120)" })
          );
        } */
      }
    }

    var ts = this.getTimeSignature(bar.number);
    var voice = new VF.Voice({
      num_beats: ts!.numberOfBeatPerMeasure!,
      beat_value: ts!.beatNoteValue!,
    })
      .addTickables(notes)
      .setMode(Voice.Mode.SOFT);

    var beams = this.buildBeams(bar, notes);

    if (notes.length > 0) {
      new VF.Formatter().joinVoices([voice]).formatToStave([voice], stave);
    }
    stave.setContext(this.context!).draw();

    voice.draw(this.context!, stave);

    if (beams.length > 0) {
      beams.forEach((x) => x.setContext(this.context!).draw());
    }
    /* if (notes.length > 0) {
      let tp = new VF.Tuplet(notes, {
        beats_occupied: 3,
        bracketed: true,
        location: -20,
        ratioed: true,
      });

      tp.setContext(this.context!).draw();
    } */

    if (clef == "treble") {
      this.context!.setFont("Arial", 8, "normal"); // Change font, size, and style

      const measureX = stave.getX(); // Get x position for the first note
      const measureY = stave.getY() + 30; // Position above the stave
      this.context!.fillText(bar.number.toString(), measureX, measureY);
    }

    return stave;
  }

  getSteamDirection(
    note: IvoryStaveNote,
    clef: string,
    beamNotes: IvoryStaveNote[]
  ) {
    let keyRef = clef == "treble" ? 50 : 29;

    if (note.beamId != null) {
      if (beamNotes.some((x) => VexflowUtils.getNoteId(x.keys[0]) > keyRef)) {
        return -1;
      } else {
        return 1;
      }
    }

    var num = VexflowUtils.getNoteId(note.keys[0]);
    if (num > keyRef) {
      return -1;
    }
    return 1;
  }

  buildBeams(bar: IvoryMeasure, notes: StaveNote[]): VF.Beam[] {
    var results: VF.Beam[] = [];

    const groupedByBeams = bar.notes.reduce<Map<number, IvoryStaveNote[]>>(
      (res, note) => {
        const beamId = note.beamId;

        if (beamId == null) {
          return res;
        }

        // Check if the Map already has this beamId
        if (!res.has(beamId)) {
          res.set(beamId, []);
        }

        // Push the current note into the appropriate group
        res.get(beamId)!.push(note); // Use non-null assertion since we've checked existence

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    if (groupedByBeams.size > 0) {
      for (let pair of groupedByBeams.values()) {
        let vexNotes: StaveNote[] = [];

        for (let note of pair.values()) {
          let index = bar.notes.indexOf(note);

          var vexNote = notes[index];
          vexNotes.push(vexNote);
        }

        var beam = new VF.Beam(vexNotes, false);
        results.push(beam);
      }
    }

    return results;
  }

  drawTitle() {
    this.context!.setFont("Arial", 20, "bold").setBackgroundFillStyle("black");

    let titleX =
      this.canvas.width / 2 -
      this.context!.measureText(this.sheet!.title).width / 2;

    titleX -= window.innerWidth / 2;
    this.context!.fillText(this.sheet!.title, titleX, 50);
  }
  getClef(staveEnum: StaveEnum) {
    switch (staveEnum) {
      case StaveEnum.Bass:
        return "bass";
      case StaveEnum.Treble:
        return "treble";
    }
  }
  draw() {
    var renderer = new VF.Renderer(this.canvas, VF.Renderer.Backends.CANVAS);

    renderer.resize(this.canvas.width, this.canvas.height);

    this.context = renderer.getContext();

    this.drawTitle();

    let barCount = Math.min(
      this.sheet?.firstStave.bars.length!,
      this.sheet?.secondStave.bars.length!
    );

    for (let barId = 0; barId < barCount; barId++) {
      let trebleBar = this.sheet?.firstStave.bars[barId]!;

      let bassBar = this.sheet?.secondStave.bars[barId]!;

      var trebleX = this.x * STAVE_WIDTH + PADDING_X;
      var trebleY = this.y * GAP_BETWEEN_GRAND_STAFF + SHEET_Y_OFFSET;

      var trebleStave = this.renderBar(
        this.sheet?.firstStave,
        trebleBar,
        trebleX,
        trebleY
      );

      var bassX = this.x * STAVE_WIDTH + PADDING_X;
      var bassY =
        this.y * GAP_BETWEEN_GRAND_STAFF + SHEET_Y_OFFSET + GAP_BETWEEN_STAFF;

      var bassStave = this.renderBar(
        this.sheet?.secondStave,
        bassBar,
        bassX,
        bassY
      );

      if (this.x == 0) {
        var brace = new VF.Flow.StaveConnector(
          trebleStave!,
          bassStave!
        ).setType(3); // 3 = brace

        brace.setContext(this.context!).draw();

        var brace = new VF.Flow.StaveConnector(
          trebleStave!,
          bassStave!
        ).setType(1); // 3 = brace

        brace.setContext(this.context!).draw();
      }
      if (this.x == STAVE_PER_LINE - 1) {
        var brace = new VF.Flow.StaveConnector(
          trebleStave!,
          bassStave!
        ).setType(0); // 3 = brace
        brace.setContext(this.context!).draw();
      }

      this.x++;

      if (this.x >= STAVE_PER_LINE) {
        this.x = 0;
        this.y++;
      }
    }
    this.drawTies();
  }
  drawTies() {
    const groupedByTies = Array.from(this.allNotes.keys()).reduce<
      Map<number, IvoryStaveNote[]>
    >(
      (res, note) => {
        const tieId = note.tieId;

        if (tieId == null) {
          return res;
        }

        if (!res.has(tieId)) {
          res.set(tieId, []);
        }

        res.get(tieId)!.push(note);

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    for (let group of groupedByTies) {
      var notes = group[1];

      for (let i = 0; i < notes.length - 1; i++) {
        var n1 = this.allNotes.get(notes[i]);
        var n2 = this.allNotes.get(notes[i + 1]);

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie = new StaveTie({ first_note: n1, last_note: null });
        } else {
          var tie = new StaveTie({ first_note: n1, last_note: n2 });
        }

        tie.setContext(this.context!).draw();

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie2 = new StaveTie({ first_note: null, last_note: n2 });
          tie2.setContext(this.context!).draw();
        }
      }
    }
  }
}
