
import { defineComponent, nextTick } from "vue";
import { getState } from "@/state/IvoryState";
import { useRoute, useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";
import * as IvoryApi from "@/IvoryApi";
import Sheet from "@/model/sheets/IvorySheet";
import SheetDrawer from "@/managers/SheetDrawer";
import * as Constants from "@/Constants";

export default defineComponent({
  data() {
    return {
      state: getState(),
      loaded: false,
      sheet: null as Sheet | null,
      Sheet: Sheet,
      hidden: true,
      error: false,
    };
  },
  setup() {
    var router = useRouter();
    return {
      router: router,
    };
  },
  computed: {},

  methods: {
    joinDiscord() {
      window.open(Constants.DiscordLink, "_blank");
    },
    openPlayer() {
      getState().playSongInNewTab(this.state.querySongId!);
    },
    goHome() {
      this.router.push({ name: "home" });
    },
  },

  async mounted() {
    mixpanel.track("Sheet view", {});

    const params = new URLSearchParams(window.location.search);
    const tempo = params.get("tempo");

    this.sheet = await IvoryApi.getSheet(
      this.state.querySongId!,
      tempo ? tempo : "120"
    );

    var refs = <any>this.$refs;

    const element = refs.header as HTMLElement;
    const rect = element.getBoundingClientRect();

    const canvas = refs.canvas;
    canvas.width = window.innerWidth;
    canvas.height =
      window.innerHeight - (rect.top + window.scrollY + rect.height) + 20000;

    if (this.sheet == null) {
      this.error = true;
    } else {
      var drawer = new SheetDrawer(canvas, this.sheet!);
      this.loaded = true;

      drawer.draw();
    }
  },
});
