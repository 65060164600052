import IvoryStave from "./IvoryStave";
import IvoryTimeSignature from "./IvoryTimeSignature";

export default class IvorySheet {
  public tempo: number = 0;

  public firstStave: IvoryStave = new IvoryStave();

  public secondStave: IvoryStave = new IvoryStave();

  public timeSignatures: IvoryTimeSignature[] = [];

  public totalDurationSeconds: number = 0;

  public title: string = "";
}
