
import { defineComponent } from "vue";

export default defineComponent({
  expose: ["getValue", "setValue"],

  methods: {
    setValue(value: string) {
      this.$el.value = value;
    },
    getValue() {
      return this.$el.value;
    },
  },
});
