
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { useRouter } from "vue-router";
import IvoryInput from "@/components/global/IvoryInput.vue";
import IvoryButton from "@/components/global/IvoryButton.vue";
import * as IvoryApi from "@/IvoryApi";
import { RegisterResult } from "@/model/accounts/RegisterResult";
import mixpanel from "mixpanel-browser";
import IvoryCheckbox from "@/components/global/IvoryCheckbox.vue";

export default defineComponent({
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    loginLayout(): any {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },
  data() {
    return {
      state: getState(),
      router: useRouter(),
      errorMessage: null as string | null,
      register: false,
    };
  },

  methods: {
    switchViewClick() {
      this.errorMessage = "";
      this.register = !this.register;
    },
    async onKeyDown(event: any) {
      if (event.key === "Enter") {
        if (this.register) {
          await this.registerClick();
        } else {
          await this.loginClick();
        }
      }
    },
    onGoogleLogin(response: any) {
      console.log(response);
    },
    async registerClick() {
      var refs = <any>this.$refs;

      if (refs.registerBtn.isLoading()) {
        return;
      }

      this.errorMessage = "";

      refs.registerBtn.toggleLoading(true);

      var password = refs.rPassword.getValue().trim();
      var passwordConf = refs.rPasswordConf.getValue().trim();
      var username = refs.rUsername.getValue().trim();
      var email = refs.rEmail.getValue().trim();

      if (refs.tosCheck.getValue() == false) {
        this.errorMessage =
          "Please accept the Terms of Use and Privacy Policy to continue.";
        refs.registerBtn.toggleLoading(false);
        return;
      }
      if (password != passwordConf) {
        this.errorMessage = "Passwords do not match";
        refs.registerBtn.toggleLoading(false);
        return;
      }

      if (email == "" || username == "" || password == "") {
        this.errorMessage = "Please fill all the fields.";
        refs.registerBtn.toggleLoading(false);
        return;
      }

      var response = await IvoryApi.register(
        refs.rUsername.getValue(),
        refs.rEmail.getValue(),
        refs.rPassword.getValue()
      );

      if (response == null) {
        this.errorMessage = "Unable to reach Ivory API.";
      } else {
        if (response?.result == RegisterResult.EmailTaken) {
          this.errorMessage = "The email address is already taken.";
        } else if (response?.result == RegisterResult.UsernameTaken) {
          this.errorMessage = "The username is already taken";
        } else if (response?.result == RegisterResult.PasswordTooSimple) {
          this.errorMessage = "Provided password is too simple.";
        } else if (response.result == RegisterResult.Ok) {
          mixpanel.track("Register", { username: username });

          this.state.account = await IvoryApi.getLoggedAccount();

          this.router.replace({ name: "home" });
        }
      }
      refs.registerBtn.toggleLoading(false);
    },
    async loginClick() {
      var refs = <any>this.$refs;

      if (refs.loginBtn.isLoading()) {
        return;
      }

      refs.loginBtn.toggleLoading(true);

      this.errorMessage = null;

      var result = await IvoryApi.login(
        refs.login.getValue(),
        refs.password.getValue()
      );

      if (result == null) {
        this.errorMessage = "Unable to reach Ivory API.";
      } else {
        if (result.ok) {
          this.router.replace({ name: "load" });
        } else {
          this.errorMessage =
            "The provided credentials (login or password) are incorrect.";
        }
      }

      refs.loginBtn.toggleLoading(false);
    },
  },
  components: { IvoryInput, IvoryButton },
});
