import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18323ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","flex-grow":"1"} }
const _hoisted_2 = {
  ref: "overlay",
  class: "overlay"
}
const _hoisted_3 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryLogo = _resolveComponent("IvoryLogo")!
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IvoryLogo, { style: {"opacity":"0.8"} }),
      _createVNode(_component_IvoryIcon, {
        size: 150,
        icon: "play"
      })
    ], 512),
    _createElementVNode("canvas", _hoisted_3, null, 512)
  ]))
}