import { KeyColor } from "@/model/KeyColor";
import * as Drawing from "@/graphics/DrawingHelper";
import { getState } from "@/state/IvoryState";
import * as Constants from "@/Constants";
import IvoryNote from "@/model/songs/IvoryNote";
import * as NoteManager from "@/managers/NoteManager";
import { PlayerState } from "@/state/PlayerState";
import * as ColorUtils from "@/utils/ColorUtils";

export default class FallingNote {
  public data: IvoryNote;
  public x: number = 0;
  public y: number = 0;
  public color: string = "";

  public reached: boolean = false;

  public inputRemoved: boolean = false;

  public removed: boolean = false;

  public visible: boolean = true;

  public selected: boolean = false;

  private state: any = null;

  constructor(data: IvoryNote) {
    this.data = data;
    this.state = getState();
  }

  public getWidth(): number {
    if (NoteManager.getKeyColor(this.data.number) == KeyColor.White) {
      return this.state.whiteKeyWidth;
    } else {
      return this.state.blackKeyWidth;
    }
  }

  public getHeight(): number {
    return Math.max(PlayerState.NoteHeightSecond * this.data.duration, 5);
  }

  public getRealY(): number {
    let realHeight = Math.max(
      PlayerState.NoteHeightSecond * this.data.realDuration,
      5
    );

    return this.y - realHeight;
  }
  public getColor() {
    return this.data.leftHand
      ? this.state.getNoteColorLHHex()
      : this.state.getNoteColorHex();
  }
  public displayed(displayRight: boolean, displayLeft: boolean) {
    return this.data.leftHand ? displayLeft : displayRight;
  }
  public draw(ctx: CanvasRenderingContext2D) {
    var color = this.getColor();

    let rgb = ColorUtils.hexToRgb(color)!;

    let height = this.getHeight();

    var n = Math.max(0, this.y + height / 2);

    var ratio = n / 100;

    var minValue = 20;

    var deltaR = Math.min(rgb.r, (rgb.r - minValue) * ratio + minValue);

    var deltaG = Math.min(rgb.g, (rgb.g - minValue) * ratio + minValue);

    var deltaB = Math.min(rgb.b, (rgb.b - minValue) * ratio + minValue);

    var deltaR = Math.max(deltaR, 0);

    var deltaG = Math.max(deltaG, 0);

    var deltaB = Math.max(deltaB, 0);

    var val = (this.y + height) / (getState().noteFlow?.getHeight()! / 3);

    val = Math.min(1, val);

    val = 1 - val;

    this.color = color; // "rgba(" + deltaR + ", " + deltaG + ", " + deltaB + "," + deltaA + ")"

    if (NoteManager.getKeyColor(this.data.number) == KeyColor.White) {
      this.color = ColorUtils.brightenColor(color, 10);
    } else {
      this.color = ColorUtils.darkenColor(color, 10);
    }

    this.color = ColorUtils.darkenColor(this.color, val * 100);

    ctx.fillStyle = this.color;

    var shadowIntensity = PlayerState.NoteShadowIntensity;

    if (this.reached) {
      this.color = ColorUtils.brightenColor(
        ColorUtils.rgbToHex(deltaR, deltaG, deltaB),
        Math.round(this.state.keyBrightnessMultiplier * 100 - 100)
      );
      shadowIntensity = 1;
    }

    if (this.selected) {
      this.color = ColorUtils.brightenColor(
        ColorUtils.rgbToHex(rgb.r, rgb.g, rgb.b),
        Math.round(this.state.keyBrightnessMultiplier * 100 - 100)
      );
      shadowIntensity = 1;
    }
    // ctx.fillStyle = "rgb(201, 201, 201)";

    ctx.shadowColor =
      "rgba(" +
      rgb.r +
      ", " +
      rgb.g +
      ", " +
      rgb.b +
      ", " +
      shadowIntensity +
      ")"; // Shadow color
    ctx.shadowBlur = PlayerState.NoteShadowBlur; // Shadow blur radius
    ctx.shadowOffsetX = 1; // Horizontal shadow offset
    ctx.shadowOffsetY = 1; // Vertical shadow offset

    // Set the fill style and draw a rectangle
    ctx.fillStyle = this.color;
    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(25,25,25,255)";

    let noteBorderRadius = 0;

    if (this.state.isMobileVersion()) {
      noteBorderRadius = 4;
    } else {
      if (NoteManager.getKeyColor(this.data.number) == KeyColor.White) {
        noteBorderRadius = 10;
      } else {
        noteBorderRadius = 5;
      }
    }

    if (height < 20) {
      height = 15;
      noteBorderRadius = 8;
    }
    Drawing.drawRoundedRect(
      ctx,
      this.x,
      this.y,
      this.getWidth(),
      height,
      noteBorderRadius
    );

    if (this.state.settings.displayPedalTailing) {
      let realHeight = PlayerState.NoteHeightSecond * this.data.realDuration;

      var diff = realHeight - this.getHeight();

      if (diff > 5) {
        Drawing.drawRect(
          ctx,
          this.x,
          this.y - diff,
          this.getWidth(),
          realHeight,
          "rgba(10,10,10,0.05)",
          true,
          "rgba(120,120,120,0.4 )"
        );
      }
    }

    /* var img = new Image();
         ctx.globalAlpha = 0.3;
         img.src = 'https://image.made-in-china.com/2f0j00DoKGzbIcZiqS/Foshan-High-Quality-60X60-Black-Tile-Black-and-White-Line-Porcelain-Marble-Floor-Tile-Jm6615.webp';
         ctx.drawImage(img,this.x,this.y,this.getWidth(),this.getHeight());
         ctx.globalAlpha = 1; */
  }
}
