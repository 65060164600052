
import { defineComponent } from "vue";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  expose: ["toggleLoading", "isLoading", "getFile", "reset"],
  data() {
    return { loading: false, fileName: null as string | null };
  },

  props: {
    icon: {
      type: String,
      required: false,
      default: "upload",
    },
    accept: {
      type: String,
      required: false,
      default: "",
    },
  },

  methods: {
    getFile() {
      return this.$el.querySelector("input").files[0];
    },
    onChange() {
      this.fileName = this.$el.querySelector("input").files[0].name;
      this.fileName = DOMUtils.limitStringSize(this.fileName!, 20);
    },

    isLoading() {
      return this.loading;
    },
    toggleLoading(loading: boolean) {
      this.loading = loading;
    },
  },
});
