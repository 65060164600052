import { Howl, Howler } from "howler";
import { getState } from "@/state/IvoryState";
import SoundElement from "@/model/sound/SoundElement";
import { PlayerState } from "@/state/PlayerState";

let soundMap = new Map<Number, Map<string, Howl>>();

export function initialize(instrument: string, progressChange: Function) {
  if (soundMap.size > 0) {
    return;
  }
  Howler.autoUnlock = true;

  Howler.autoSuspend = false;

  const loadPromises: Promise<any>[] = [];

  let instrumentPath = `/sounds/${instrument}`;

  let velocities = ["forte", "piano", "mezzo-forte"];

  let index = 0;

  Howler.volume(1);

  for (var soundVelocity of velocities) {
    var dir = "/" + soundVelocity;

    for (let n = 0; n < 88; n++) {
      let path = instrumentPath + dir + "/" + n + ".mp3";

      var sound = new Howl({
        preload: true,
        src: [path],
      });

      const loadPromise = new Promise((resolve) => {
        sound.once("load", () => {
          index++;

          progressChange(index / velocities.length / 88);

          resolve(true);
        });
      });

      loadPromises.push(loadPromise);

      sound.load();

      if (soundMap.get(n) == null) {
        soundMap.set(n, new Map<string, Howl>());
      }
      soundMap.get(n)?.set(soundVelocity, sound);
    }
  }

  return Promise.all(loadPromises);
}

export function isContextSuspended() {
  return Howler.ctx.state == "suspended";
}
export function toggleMute(muted: boolean) {
  Howler.mute(muted);
}
export function play(noteNumber: number, velocity: number): SoundElement {
  var dynamic = "piano";

  if (velocity > 77) {
    dynamic = "forte";
  } else if (velocity > 55) {
    dynamic = "mezzo-forte";
  }

  var template = soundMap.get(noteNumber)!.get(dynamic)!;
  let id = template.play();

  template.volume(1, id);

  return new SoundElement(id, dynamic, noteNumber);
}
export function fade(sound: SoundElement) {
  var nb = 1000 * PlayerState.ReverbLevel;
  soundMap.get(sound.noteNumber)?.get(sound.dynamic)!.fade(1, 0, nb, sound.id);
}
