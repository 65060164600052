
import { getState } from "@/state/IvoryState";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      appState: getState(),
    };
  },
  props: {
    text: {
      type: Boolean,
      required: false,
      default: true,
    },
    sizeMul: {
      type: Number,
      required: false,
      default: 1,
    },
  },
});
